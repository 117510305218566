<template>
  <v-layout row wrap>
    <v-flex xs10 sm5 lg3 v-if="totals">
      <v-card>
        <v-card-title class="title">QSOs</v-card-title>
        <v-divider></v-divider>

        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th></th>
                <th>Month</th>
                <th>Year</th>
                <th>All Time</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Phone</th>
                <td>0</td>
                <td>0</td>
                <td>0</td>
              </tr>
              <tr>
                <th>CW</th>
                <td>0</td>
                <td>0</td>
                <td>0</td>
              </tr>
              <tr>
                <th>Data</th>
                <td>0</td>
                <td>0</td>
                <td>0</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>Total</th>
                <td>0</td>
                <td>0</td>
                <td>0</td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-card>
    </v-flex>

    <v-flex xs10 sm5 lg3 v-if="totals">
      <v-card>
        <v-card-title class="title">Park-to-Park</v-card-title>
        <v-divider></v-divider>

        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th></th>
                <th>Month</th>
                <th>Year</th>
                <th>All Time</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Phone</th>
                <td>0</td>
                <td>0</td>
                <td>0</td>
              </tr>
              <tr>
                <th>CW</th>
                <td>0</td>
                <td>0</td>
                <td>0</td>
              </tr>
              <tr>
                <th>Data</th>
                <td>0</td>
                <td>0</td>
                <td>0</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>Total</th>
                <td>0</td>
                <td>0</td>
                <td>0</td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-card>
    </v-flex>

    <v-flex xs8 sm4 lg2 v-if="totals">
      <v-card>
        <v-card-title class="title">Activations</v-card-title>
        <v-divider></v-divider>
        <v-list class="transparent">
          <v-list-item>
            <v-list-item-title>Month</v-list-item-title>
            <v-list-item-subtitle class="text-right">{{
              totals.phone
            }}</v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>Year</v-list-item-title>
            <v-list-item-subtitle class="text-right">{{
              totals.cw
            }}</v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>All Time</v-list-item-title>
            <v-list-item-subtitle class="text-right">{{
              totals.data
            }}</v-list-item-subtitle>
          </v-list-item>
        </v-list>
      </v-card>
    </v-flex>

    <v-flex xs8 sm4 lg2 v-if="totals">
      <v-card>
        <v-card-title class="title">Parks</v-card-title>
        <v-divider></v-divider>
        <v-list class="transparent">
          <v-list-item>
            <v-list-item-title>Month</v-list-item-title>
            <v-list-item-subtitle class="text-right">{{
              totals.phone
            }}</v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>Year</v-list-item-title>
            <v-list-item-subtitle class="text-right">{{
              totals.cw
            }}</v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>All Time</v-list-item-title>
            <v-list-item-subtitle class="text-right">{{
              totals.data
            }}</v-list-item-subtitle>
          </v-list-item>
        </v-list>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState } from "vuex";
// import UserStats from '@/components/UserStats'
// import axios from 'axios'

export default {
  beforeMount() {
    //alert('beforeMount ' + this.id)
  },
  // mounted () {
  //   alert('mounted ' + this.id)
  //   this.updateParkInfo()
  // },
  beforeCreate() {
    // alert('beforeCreate ' + this.id)
    // this.updateParkInfo()
  },
  created() {
    //alert('created ' + this.id)
    this.updateStats();
  },
  beforeUpdate() {
    // alert('beforeUpdate ' + this.id)
    // this.updateParkInfo()
  },
  updated() {
    // alert('updated ' + this.id)
    //this.updateStats()
  },
  methods: {
    updateStats() {
      if (!this.totals) this.$store.dispatch("getTotals");
      //axios
      //    .get(`https://${process.env.VUE_APP_API_URL}/totals`)
      //    .then(response => response.data)
      //    .then(json => this.totals = json.)
    },
  },
  data() {
    return {};
  },
  computed: mapState({
    tableView: (state) => state.config.tableView,
    isAuthenticated: (state) => state.user.isAuthenticated,
    totals: (state) => state.totals.data[0],
    loadingTotals: (state) => state.totals.loading,
  }),
  components: {
    //UserStats,
  },
};
</script>
